import React, { useEffect } from 'react'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider'
import Snackbar from '@mui/material/Snackbar'

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useActionsAPI from 'components/utils/actionsAPI'
import { sendEmail } from 'components/utils/sendEmail'

const CardMenu = React.memo(({
  contextMenu,
  handleMenuClose,
  handleNewData = () => { },
  card,
  isMobile,
  menuStyle = {
    position: 'absolute',
    right: 6,
    bottom: 0,
    zIndex: 100,
    backgroundColor: 'white',
  },
  primaryAction = null,
  primaryActionText = null,
  secondaryAction = null,
  secondaryActionText = null,
  showButton = true,
  showEditForm = () => { },
  showEventMenu = false,
  showPlaceMenu = true,
  snackBarPosition = { vertical: 'top', horizontal: 'center' },
  snackBarStyle = { top: "100px", right: "40px" },
  user = null,
  ...props
}) => {
  const { patchEvent } = useActionsAPI()

  const [showMessage, setShowMessage] = React.useState(false)
  const [message, setMessage] = React.useState('Shared and copied to clipboard!')
  const setAndShowMessage = (message) => {
    setShowMessage(true)
    setMessage(message ? message : 'Success!')
  }

  const copyID = (event) => {
    event.preventDefault()
    event.stopPropagation()
    navigator.clipboard.writeText(card.id)
    handleMenuClose()
  }

  const copyName = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const name = card?.title || card?.properties?.name
    navigator.clipboard.writeText(name)
    handleMenuClose()
    setAndShowMessage('Copied to clipboard!')
  }

  const copySearch = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const name = card?.title || card?.properties?.name
    const type = card?.card_type
    const address = card?.properties?.address

    let googleSearch = `${name} ${address}`
    if (type === 'event') {
      const start_date = card?.start_date.split('T')[0]
      const venue = card?.venue
      googleSearch = `${name} ${start_date} ${venue}`
    }

    navigator.clipboard.writeText(googleSearch)
    handleMenuClose()
  }

  const is_featured = card?.is_featured

  const setFeatured = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const data = { is_featured: !is_featured }
    patchEvent(eventID, data)
    handleMenuClose()
    handleNewData && handleNewData(data)
    setAndShowMessage('Event featured')
  }

  const setApproved = (event, is_approved = true) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const data = { is_approved: is_approved }
    patchEvent(eventID, data)
    sendEmail(
      `User ${user?.email} updated approval: ${eventID} ${is_approved}`,
      { email: 'steve@vibemap.com', subject: 'Event approval updated', eventID, is_approved }
    )

    handleMenuClose()
    handleNewData(data)
    setAndShowMessage(is_approved ? 'Event approved' : 'Event disapproved and hidden')
  }

  const setDisapproved = (event, is_approved = true) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const data = { is_approved: false }
    patchEvent(eventID, data)
    handleMenuClose()
    handleNewData(data)
    setAndShowMessage(is_approved ? 'Event approved' : 'Event disapproved and hidden')
  }

  const editBusiness = (event, showDialog = true) => {
    event.preventDefault()
    event.stopPropagation()
    const placeID = card?.id
    const slug = card?.properties?.slug
    const pageLink = `/edit-business?id=${slug ? slug : placeID}`

    if (showDialog && !isMobile) {
      showEditForm(true, placeID)
    } else {
      window.open(pageLink, '_blank')
      handleMenuClose()
    }
  }

  const editEvent = (event, showDialog = true) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const slug = card?.properties?.slug
    const pageLink = `/edit-event?id=${slug ? slug : eventID}`

    if (showDialog) {
      showEditForm(true, eventID)
    } else {
      window.open(pageLink, '_blank')
      handleMenuClose()
    }
  }

  const copyLink = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const slug = card?.properties?.slug
    const cardID = card?.id
    const type = card?.card_type

    const pageLink = `/${type}s/details/${slug ? slug : cardID}`
    navigator.clipboard.writeText(`${window.location.origin}${pageLink}`)
    handleMenuClose()
    setAndShowMessage('Link copied to clipboard!')
  }

  const actionLookupTable = {
    approve: (event) => setApproved(event),
    disapprove: (event) => setDisapproved(event),
    edit: editEvent,
    editBusiness: editBusiness,
    feature: setFeatured,
    copyID: copyID,
    copyLink: copyLink,
  };

  const handlePrimaryAction = (event, actionKey) => {
    const action = actionLookupTable[actionKey];
    if (action) {
      action(event);
    } else {
      console.error('Invalid action key:', actionKey);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = anchorEl ? true : false
  const handleClick = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const menuPosition = contextMenu !== null
    ? {
      top: contextMenu?.mouseY,
      left: contextMenu?.mouseX
    }
    : anchorEl
      ? {
        top: anchorEl.getBoundingClientRect().top + 10,
        left: anchorEl.getBoundingClientRect().left
      }
      : undefined


  if (anchorEl) console.log('menuPosition ', menuPosition, anchorEl);

  return (
    <div style={menuStyle}>
      <Snackbar
        autoHideDuration={6000}
        open={showMessage}
        message={message}
        anchorOrigin={snackBarPosition}
        style={snackBarStyle}
        />

      {primaryAction
        ? <a
            className="button secondary small"
            onClick={(event) => handlePrimaryAction(event, primaryAction)}
          >
            {primaryActionText}
          </a>
        : null
      }
      {secondaryAction
        ? <a
            className="button secondary small"
            onClick={(event) => handlePrimaryAction(event, secondaryAction)}
          >
            {secondaryActionText}
          </a>
        : null
      }

      {showButton
        ? (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            style={{ border: '1px #DDD solid' }}
            onClick={handleClick}
            >
            <MoreVertIcon />
          </IconButton>
        )
        : null
      }

      <Menu
        id="long-menu"
        anchorPosition={menuPosition}
        anchorEl={anchorEl}
        open={open || contextMenu !== null}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'long-button', }}
        anchorReference="anchorPosition" >

        {showEventMenu ? (
          <MenuList>
            <MenuItem onClick={setFeatured}>{is_featured ? 'Don\'t Feature Event' : 'Feature Event'}</MenuItem>
            <MenuItem onClick={setApproved}>Hide Event</MenuItem>
            <MenuItem onClick={editEvent}>Edit Event</MenuItem>
            <Divider />
            <MenuItem onClick={copyName}>Copy Event Name</MenuItem>
            <MenuItem onClick={copySearch}>Copy Event Search</MenuItem>
            <MenuItem onClick={copyID}>Copy Event ID</MenuItem>
            <MenuItem onClick={copyLink}>Copy Event Link</MenuItem>
          </MenuList>
        ) : null}

        {showPlaceMenu
          ? <MenuList>
            <MenuItem onClick={editBusiness}>Edit Business</MenuItem>
            <MenuItem onClick={setFeatured}>{is_featured ? 'Don\'t Feature Event' : 'Feature Event'}</MenuItem>
            <Divider />
            <MenuItem onClick={copyName}>Copy Place Name</MenuItem>
            <MenuItem onClick={copySearch}>Copy Place Search</MenuItem>
            <MenuItem onClick={copyID}>Copy Place ID</MenuItem>
            <MenuItem onClick={copyLink}>Copy Place Link</MenuItem>
          </MenuList>
          : null
        }

      </Menu>
    </div>
  )
})

export default CardMenu