import React, { useEffect, useState } from 'react'
import { pure } from 'recompose'
import { dataStore, pageStore, userStore } from "state/store-zustand";

import { fetchPlacesDetails } from "components/utils/testHelpers.js"


import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'

import EventForm from 'components/forms/addEventFormBasic'

import AuthDialog from "components/account/authDialog"
import Branding from "components/account/branding"
import CardMenu from "components/elements/cards/menu"


import '../styles/search.scss'
import 'components/forms/form.scss'

const EditEvent = ({ data, location, params,...props }) => {
  // Page context and options
  const pageLoader = usePageLoading()
  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const isClient = pageStore((state) => state.isClient)
  const embedded = pageStore((state) => state.embedded)
  const eventID = dataStore((state) => state.eventID)
  const eventCurrent = dataStore((state) => state.eventCurrent)
  const setEventCurrent = dataStore((state) => state.setEventCurrent)
  const showCardMenu = pageStore((state) => state.showCardMenu)


  const isLoggedIn = userStore((state) => state.isLoggedIn)
  const user = userStore((state) => state.user)

  // Form content and state
  const alreadyLoaded = eventCurrent != null
  const [isLoading, setIsLoading] = useState(!alreadyLoaded)
  const [hasError, setHasError] = useState(false)

  const [contextMenu, setContextMenu] = React.useState(null)

  const handleMenuClose = React.useCallback(() => {
    setContextMenu(null);
  }, []);

  const [clearForm, setClearForm] = useState(false)

  const handleClick = (event, data) => {
    //console.log('handleClick ', event, data)
    if (data.details) {
      setEvent(data.details)
    }
  }

  const handleSubmit = async (event, data) => {
    debugger
    console.log('TODO: handleSubmit ', event, data);
    //setEventCurrent(null)
    //setClearForm(true)
    //setTitle('Thanks for helping us!')
    //setMessage(<p>Vibemap will include this business in our city pages, vibe guides, and other content.</p>)
  }

  if (!isClient) {
    return null
  }

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      const details = await fetchPlacesDetails(id, 'event', true)
        .catch(e => {
          console.log(`Error with event `, id, e)
          setHasError(true)
        })

      if (details) {
        setEventCurrent(details.data)
        setIsLoading(false)
        const place = details.data?.properties?.hotspots_place
      } else {
        setHasError(true)
        setIsLoading(false)
      }
    }

    if (eventCurrent == null) {
      fetchData(eventID)
    }

  }, [eventID])


  const EventFormMemo = React.memo(EventForm);
  const is_approved = eventCurrent?.properties?.is_approved

  return (
    <>
      <main className={`page add-event ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ? theme : ''}`}>

        <Branding />

        <AuthDialog />

        {isLoggedIn
          ? <CardMenu
              contextMenu={contextMenu}
              handleMenuClose={handleMenuClose}
              //handleNewData={handleNewData}
              card={eventCurrent}
              menuStyle={{ top: '1.2rem', right: '7rem', position: 'absolute' }}
              primaryAction={is_approved == true ? 'disapprove' : 'approve'}
              secondaryAction={is_approved == true ? 'approve' : 'disapprove'}
              primaryActionText={is_approved == true ? '🚫 Disapprove' : '✅ Approve'}
              secondaryActionText={is_approved == true ? '✅' : '🚫'}
              showButton={showCardMenu}
              //showEditForm={handleShowEditForm}
              showPlaceMenu={false}
              showEventMenu={true}
              user={user}
            />
          : null
        }


        <div className='container'>

            <EventFormMemo
                onSubmitCallback={handleSubmit}
                showSearch={false}
                lookupEvent={false}
                updateOnly={true} />
        </div>
      </main>
    </>
  )
}

export default pure(EditEvent);